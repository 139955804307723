<template>
    <div>

        <div class="card-toolbar mb-5">
            <router-link v-if="$can('credit_type.create')" to="/points-credits/credit-types/create"  class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon> {{ $t('credit_types.new_credit_type') }}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="name">{{$t('credit_types.name')}}</label>
                            <input v-model="filters.name" type="text" id="name" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('credit_types.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('credit_types.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('Added_By')}}
                            </label>
<!--                            <select name="" id="user_id" v-model="filters.user_id" type="text" class="custom-select">-->
<!--                                <option value="">{{$t('all')}}</option>-->
<!--                                <option v-for="(row, index) in users" :value="row.id" :key="index">{{ row.name }}</option>-->
<!--                            </select>-->
                            <multiselect v-model="user"
                                         id="store"
                                         :placeholder="$t('Added_By')"
                                         label="name"
                                         track-by="id"
                                         :options="users"
                                         :multiple="false"
                                         :taggable="true"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <b-button class="mt-auto mb-0 mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mb-0 mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <custom-export-data v-if="$can('credit_types.export_data')"  :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.credit_types')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('credit_types.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else size="lg" :disabled="true" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('credit_type.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('credit_type.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <v-btn icon color="pink" :to="`/points-credits/credit-types/view/${props.row.id}`">
                            <v-icon small v-b-tooltip.hover :title="$t('view')" class="mr-2">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
        
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";


    export default {
        name: "index-credit-types",
        components: {},
        data() {
            return {
                mainRoute: 'pointscredits/credit-types',
                subMainRoute: 'pointscredits/credit-type',
                mainRouteDependency: 'base/dependency',
                routeChangeStatus : 'pointscredits/credit-type/change-status',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    user_id: null,
                    to_date: '',
                    from_date: '',
                    name: '',
                    status: '',
                },

                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                users: [],
                user: null,

                columns: ['name', 'unit', 'created_at','added_by', 'status', 'actions'],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('credit_types.name')] = 'name';
                fields[this.$t('credit_types.unit')] = 'unit';
                fields[this.$t('credit_types.created_at')] = 'created_at';
                fields[this.$t('credit_types.added_by')] = 'added_by';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('credit_types.name'),
                        unit: that.$t('credit_types.unit'),
                        added_by: that.$t('credit_types.added_by'),
                        created_at: that.$t('credit_types.created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        watch:{
            user: function (val) {
                if (val){
                    this.filters.user_id = val.id;
                }else{
                    this.filters.user_id = null;
                }
            }
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.credit_types")}]);
            this.getUsers();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = '';
                this.filters.from_date = '';
                this.filters.to_date = '';
                this.filters.status = '';
                this.filters.user_id = '';
                this.user = null;

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'credit-types.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getUsers() {
                ApiService.get(this.mainRouteDependency + "/users").then((response) => {
                    this.users = response.data.data;
                });
            },

            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    status: (status ? '1' : '0'),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
        },
    };
</script>
